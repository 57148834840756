import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Row from "../../../styles/Row"
import { hex2rgba } from "../../../styles/tools"
import ArrowRight from "../../../images/arrow-right.svg"
import { useTranslateValue } from "../../../data/TranslateContext"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 2rem 0;

  @media (max-width: 540px) {
    padding: 1rem 0;
  }

  .gatsby-image-wrapper {
    margin: 0.75rem;
    width: 200px;
    /* height: 240px; */

    border-radius: 1.5rem;
    box-shadow: 0 2px 8px 4px
      ${props => hex2rgba(props.theme.color.primary, 0.1)};

    background-color: ${props => props.theme.color.whiteBg};

    @media (max-width: 540px) {
      margin: 0.5rem;
      width: 140px;

      &:nth-child(5) {
        display: none;
      }
    }
  }
`
const Content = styled.div`
  margin-bottom: 3rem;
  text-align: center;

  h2 {
    display: block;
    margin-bottom: 0.5rem;
  }

  a {
    display: inline-flex;
    align-items: center;
    font-style: italic;

    svg {
      display: block;
      margin-left: 0.5rem;

      .arrow-right {
        fill: ${props => props.theme.color.primary};
      }
    }
  }
`

export default function Instagram(props) {
  const images = useStaticQuery(graphql`
    query frontPageInstagramImages {
      image1Mobile: file(relativePath: { eq: "instagram/instagram-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image1Desktop: file(relativePath: { eq: "instagram/instagram-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      image2Mobile: file(relativePath: { eq: "instagram/instagram-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2Desktop: file(relativePath: { eq: "instagram/instagram-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      image3Mobile: file(relativePath: { eq: "instagram/instagram-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image3Desktop: file(relativePath: { eq: "instagram/instagram-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      image4Mobile: file(relativePath: { eq: "instagram/instagram-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image4Desktop: file(relativePath: { eq: "instagram/instagram-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      image5Mobile: file(relativePath: { eq: "instagram/instagram-05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image5Desktop: file(relativePath: { eq: "instagram/instagram-05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const image1Sources = [
    images.image1Mobile.childImageSharp.fluid,
    {
      ...images.image1Desktop.childImageSharp.fluid,
      media: `(min-width: 540px)`,
    },
  ]
  const image2Sources = [
    images.image2Mobile.childImageSharp.fluid,
    {
      ...images.image2Desktop.childImageSharp.fluid,
      media: `(min-width: 540px)`,
    },
  ]
  const image3Sources = [
    images.image3Mobile.childImageSharp.fluid,
    {
      ...images.image3Desktop.childImageSharp.fluid,
      media: `(min-width: 540px)`,
    },
  ]
  const image4Sources = [
    images.image4Mobile.childImageSharp.fluid,
    {
      ...images.image4Desktop.childImageSharp.fluid,
      media: `(min-width: 540px)`,
    },
  ]
  const image5Sources = [
    images.image5Mobile.childImageSharp.fluid,
    {
      ...images.image5Desktop.childImageSharp.fluid,
      media: `(min-width: 540px)`,
    },
  ]

  const [{ language }] = useTranslateValue()

  return (
    <Row>
      <Wrapper>
        <Img fluid={image1Sources} />
        <Img fluid={image2Sources} />
        <Img fluid={image3Sources} />
        <Img fluid={image4Sources} />
        <Img fluid={image5Sources} />
      </Wrapper>

      <Content>
        <h2>
          <a
            href="https://www.instagram.com/therealfreal/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontStyle: "normal" }}
          >
            {language === "en" && "We're @therealfreal"}
            {language === "fr" && "Nous sommes @therealfreal"}
          </a>
        </h2>
        <a
          href="https://www.instagram.com/therealfreal/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {language === "en" && (
            <>
              Follow us on the gram <ArrowRight />
            </>
          )}
          {language === "fr" && (
            <>
              Suis nous sur instagram <ArrowRight />
            </>
          )}
        </a>
      </Content>
    </Row>
  )
}
